<script>
import ArticleSkeletonLoader from 'shared/components/ArticleSkeletonLoader.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { emitter } from 'shared/helpers/mitt';

export default {
  name: 'IframeLoader',
  components: {
    ArticleSkeletonLoader,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      showEmptyState: !this.url,
    };
  },
  mounted() {
    emitter.on(BUS_EVENTS.POPOUT_IFRAME, this.onPopout);
  },
  destroyed() {
    emitter.off(BUS_EVENTS.POPOUT_IFRAME, this.onPopout);
  },
  methods: {
    handleIframeLoad() {
      // Once loaded, the loading state is hidden
      this.isLoading = false;
    },
    handleIframeError() {
      // Hide the loading state and show the empty state when an error occurs
      this.isLoading = false;
      this.showEmptyState = true;
    },
    onPopout() {
      let url = null;
      try {
        url = new URL(this.$refs.iframe.contentWindow.location.href);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        url = new URL(this.url);
      }

      url.searchParams.delete('show_plain_layout');
      url.searchParams.delete('theme');

      window.open(url.toString(), '_blank');
    },
  },
};
</script>

<template>
  <div class="relative overflow-hidden pb-1/2 h-full">
    <iframe
      v-if="url"
      ref="iframe"
      :src="url"
      class="absolute w-full h-full top-0 left-0"
      @load="handleIframeLoad"
      @error="handleIframeError"
    />
    <ArticleSkeletonLoader
      v-if="isLoading"
      class="absolute w-full h-full top-0 left-0"
    />
    <div
      v-if="showEmptyState"
      class="absolute w-full h-full top-0 left-0 flex justify-center items-center"
    >
      <p>{{ $t('PORTAL.IFRAME_ERROR') }}</p>
    </div>
  </div>
</template>
